
import { IRole } from '@/types';
import { Vue, Component } from 'vue-property-decorator';
import { getRoleList } from '@/api/role';
import { UserModule } from '@/store/user';

@Component({
  name: 'JoinTerms',
})
export default class extends Vue {
  mounted() {
    if (UserModule.token) {
      this.$router.push({ name: 'Index' });
    } else {
      getRoleList().then((res: any) => {
        this.roleList = res.data;
      });
    }
  }

  private roleList: IRole[] = [];

  private activeIndex = 0;

  private useRuleVisible = true;

  private privacyRuleVisible = false;

  private useRuleState = false;

  private privacyRuleState = false;

  private allAgreeState = false;

  private handleClickRole(index: number) {
    if (this.activeIndex !== index) {
      this.useRuleState = false;
      this.privacyRuleState = false;
      this.allAgreeState = false;
    }
    this.activeIndex = index;
  }

  private handleClickViewRule(isUseRule: boolean) {
    if (isUseRule) this.useRuleVisible = !this.useRuleVisible;
    else this.privacyRuleVisible = !this.privacyRuleVisible;
  }

  private handleClickAgreeAll() {
    setTimeout(() => {
      if (this.allAgreeState) {
        this.useRuleState = true;
        this.privacyRuleState = true;
      } else {
        this.useRuleState = false;
        this.privacyRuleState = false;
      }
    }, 100);
  }

  private handleClickNext() {
    if (!this.useRuleState) {
      alert('이용약관에 동의해주세요.');
      (this.$refs.useRule as any).focus();
      return;
    }
    if (!this.privacyRuleState) {
      alert('개인정보 수집 및 이용에 동의해주세요.');
      (this.$refs.privacyRule as any).focus();
      return;
    }
    const role = this.roleList[this.activeIndex].roleCode;
    if (this.$route.name === 'AppJoinTerms') this.$router.push({ name: 'AppUserJoin', params: { roleCode: role } });
    else this.$router.push({ name: 'UserJoin', params: { roleCode: role } });
  }

  private getTerm(roleName: string) {
    return `제 1장 총칙-${roleName}
      제 1조(목적)
      이 이용약관은 창조경제타운(이하 "당 사이트")에서 제공하는 인터넷 서비스(이하 '서비스')의 가입조건, 당 사이트와 이용자의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
      제 2조(용어의 정의)
      ①"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
      ②서비스란 창조경제타운에서 제공하는 제반정보(창조아이디어 제안, 공유아이디어 공간, 멘토와의 대화, 아이디어 사업 지원정보 등)를 말합니다.
      ③"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.
      ④"비회원"이라 함은 회원으로 가입하지 않고 창조경제타운이 제공하는 서비스를 이용하는 자를 말합니다.
      ⑤"회원 아이디(ID)"라 함은 회원의 식별 및 서비스 이용을 위하여 자신이 선정한 문자 및 숫자의 조합을 말합니다.
      ⑥"비밀번호"라 함은 회원이 자신의 개인정보 및 직접 작성한 비공개 콘텐츠의 보호를 위하여 선정한 문자, 숫자 및 특수문자의 조합을 말합니다.`;
  }

  private getPrivacyTerm() {
    return `제 1장 총칙
      제 1조(목적)
      이 이용약관은 창조경제타운(이하 "당 사이트")에서 제공하는 인터넷 서비스(이하 '서비스')의 가입조건, 당 사이트와 이용자의 권리, 의무, 책임사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
      제 2조(용어의 정의)
      ①"이용자"라 함은 당 사이트에 접속하여 이 약관에 따라 당 사이트가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
      ②서비스란 창조경제타운에서 제공하는 제반정보(창조아이디어 제안, 공유아이디어 공간, 멘토와의 대화, 아이디어 사업 지원정보 등)를 말합니다.
      ③"회원"이라 함은 서비스를 이용하기 위하여 당 사이트에 개인정보를 제공하여 아이디(ID)와 비밀번호를 부여 받은 자를 말합니다.
      ④"비회원"이라 함은 회원으로 가입하지 않고 창조경제타운이 제공하는 서비스를 이용하는 자를 말합니다.
      ⑤"회원 아이디(ID)"라 함은 회원의 식별 및 서비스 이용을 위하여 자신이 선정한 문자 및 숫자의 조합을 말합니다.
      ⑥"비밀번호"라 함은 회원이 자신의 개인정보 및 직접 작성한 비공개 콘텐츠의 보호를 위하여 선정한 문자, 숫자 및 특수문자의 조합을 말합니다.`;
  }
}
